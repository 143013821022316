.chat-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.chat-box {
  max-height: 75vh;
  width: 100%;
  margin: 10px;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #ccc;
  position: relative; /* Added to position the clear chat button */
}

.message {
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 100%;
  word-wrap: break-word;
}

.user-message,
.ai-message {
  font-size: 14px;
  font-weight: 400;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  width: fit-content;
  max-width: 90%;
  word-wrap: break-word;
}

.user-message {
  background-color: #3B82F6;
  color: white;
  align-self: flex-end;
  margin-left: auto;
  text-align: right;
}

.ai-message {
  background-color: white;
  color: #2d3748;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin-bottom: 12px;
}

.chat-messages {
  list-style: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 70vh;
}

.chat-form {
  flex-grow: 1;
  display: flex;
  align-items: end;
  padding: 10px;
  background: #f2f2f2;
}

.chat-form input,
.chat-form textarea {
  flex-grow: 1;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #ccc;
  margin-right: 8px;
  background: white;
  color: black;
  font-size: 14px;
  font-family: Arial, sans-serif; /* Set the desired font family here */
}

.chat-form textarea {
  flex-grow: 1;
  padding: 10px;
  border-radius: 18px;
  border: 2px solid #ccc;
  margin-right: 8px;
  line-height: 1.4;
  min-height: 35px;
  max-height: 80px;
  box-sizing: border-box;
  resize: none;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: white;
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.chat-form textarea::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.chat-form input:focus,
.chat-form textarea:focus {
  outline: none;
  border-color: #3B82F6;
}

.chat-form button {
  padding: 0 18px;
  border-radius: 20px;
  height: 36px; /* Set the initial height to a single line */
  margin-bottom: 2px;
  border: none;
  background-color: #3B82F6;
  color: white;
  font-weight: 400;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.chat-form button:hover {
  background-color: #2563EB;
  box-shadow: 0 4px 8px #b0c4de;
}

.chat-form button:active {
  background-color: #3B82F6;
  box-shadow: 0 2px 4px #b0c4de;
}


@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.pulsating-text {
  animation: pulsate 1s ease-in-out infinite;
}

.copy-btn {
  padding: 3px 5px;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  transition: background-color 0.3s;
}

.copy-btn:hover {
  background-color: #d4d4d4;
}

.clear-chat-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #e0e0e0;
  color: #333;
  cursor: pointer;
  font-size: 10px;
  box-shadow: 0 2px 6px #b0c4de;
  transition: background-color 0.3s, box-shadow 0.3s;
  z-index: 1;
}

.clear-chat-btn:hover {
  background-color: #ff5252;
}

.clear-chat-btn:active {
  background-color: #c0c0c0;
}

/* Mode-specific borders */
.chat-form textarea.general {
  border-color: rgba(74, 144, 226, 0.5);
}

.chat-form textarea.academic {
  border-color: rgba(124, 58, 237, 0.5);
}

.chat-form textarea.rx {
  border-color: rgba(16, 185, 129, 0.5);
}

/* Focus states */
.chat-form textarea.general:focus {
  border-color: rgba(74, 144, 226, 1);
  outline: none;
}

.chat-form textarea.academic:focus {
  border-color: rgba(124, 58, 237, 1);
  outline: none;
}

.chat-form textarea.rx:focus {
  border-color: rgba(16, 185, 129, 1);
  outline: none;
}

.markdown-content {
  width: 100%;
  font-size: 14px;
  line-height: 1.6;
  color: #2d3748;
}

.markdown-content p {
  margin: 0.8em 0;
}

.markdown-content h2 {
  font-size: 1.5em;
  margin: 1.2em 0 0.8em;
  font-weight: 600;
  color: #1a202c;
  border-bottom: 1px solid #edf2f7;
  padding-bottom: 0.3em;
}

.markdown-content h3 {
  font-size: 1.25em;
  margin: 1em 0 0.6em;
  font-weight: 600;
  color: #2d3748;
}

.markdown-content ul, .markdown-content ol {
  margin: 0.8em 0;
  padding-left: 1.5em;
}

.markdown-content li {
  margin: 0.4em 0;
  line-height: 1.5;
}

.markdown-content strong {
  font-weight: 600;
  color: #1a202c;
}

.markdown-content em {
  font-style: italic;
  color: #4a5568;
}

.markdown-content code {
  font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  font-size: 0.9em;
  padding: 0.2em 0.4em;
  background-color: #edf2f7;
  border-radius: 4px;
  color: #4a5568;
}

.markdown-content pre {
  background-color: #f7fafc;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
  border: 1px solid #edf2f7;
  margin: 1em 0;
}

.markdown-content pre code {
  background-color: transparent;
  padding: 0;
  font-size: 0.9em;
  color: #4a5568;
  line-height: 1.6;
}

.markdown-content blockquote {
  border-left: 4px solid #3B82F6;
  margin: 1em 0;
  padding: 0.5em 1em;
  background-color: #f8fafc;
  color: #4a5568;
  border-radius: 0 4px 4px 0;
}

.markdown-content table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
  font-size: 0.95em;
}

.markdown-content th {
  background-color: #f8fafc;
  font-weight: 600;
  color: #1a202c;
  border: 1px solid #e2e8f0;
  padding: 0.75em 1em;
  text-align: left;
}

.markdown-content td {
  border: 1px solid #e2e8f0;
  padding: 0.75em 1em;
  color: #4a5568;
}

.markdown-content a {
  color: #3B82F6;
  text-decoration: none;
  transition: color 0.2s ease;
}

.markdown-content a:hover {
  color: #2563EB;
  text-decoration: underline;
}

.markdown-content hr {
  border: none;
  border-top: 1px solid #e2e8f0;
  margin: 1.5em 0;
}