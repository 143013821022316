.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  backdrop-filter: blur(4px);
}

.popup {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
  width: 450px;
  animation: popupFadeIn 0.2s ease-out;
}

@keyframes popupFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.popup h3 {
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.popup-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.popup-fields input {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  font-size: 15px;
  transition: all 0.2s ease;
  background-color: #f9fafb;
}

.popup-fields input:focus {
  outline: none;
  border-color: #3B82F6;
  background-color: white;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.popup-fields input::placeholder {
  color: #9ca3af;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 8px;
}

.popup-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.cancel-button {
  background-color: #f3f4f6;
  color: #4b5563;
}

.cancel-button:hover {
  background-color: #e5e7eb;
}

.primary-button {
  background-color: #3B82F6;
  color: white;
}

.primary-button:hover {
  background-color: #2563eb;
  transform: translateY(-1px);
}

.delete-button {
  background-color: #fee2e2;
  color: #dc2626;
}

.delete-button:hover {
  background-color: #fecaca;
}

.quick-picks-container {
  max-height: 15vh;
  overflow-y: auto;
  padding-right: 10px;
}

.quick-picks-buttons {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #888 #f2f2f2;
  align-items: center;
}

.quick-picks-buttons::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.quick-picks-buttons::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.quick-picks-buttons::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

.quick-pick-button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #3B82F6;
  color: white;
  font-weight: bold;
  flex-shrink: 0;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quick-pick-button:hover {
  background-color: #316ed0;
}

.add-quick-pick {
  width: 38px;
  height: 38px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #333;
  font-size: 24px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-quick-pick:hover {
  background-color: #e0e0e0;
}

.edit-icon {
  margin-left: 8px;
  cursor: pointer;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
}