@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

.animate-bounce {
  animation: bounce 2s infinite;
}

.animate-pulse {
  animation: pulse 1.5s infinite;
}

.loading-screen {
  position: fixed;
  inset: 0;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.loading-screen-stars {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/stars-background.png');
  background-size: cover;
  background-position: center;
}

.logo-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  font-size: 0;
}

.logo {
  width: 120px;
  height: auto;
}

.floating-logo {
  width: 120px;
  height: 120px;
  animation: float 3s ease-in-out infinite;
  font-size: 0;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) scale(1);
    filter: drop-shadow(0 10px 15px rgba(25, 35, 126, 0.2));
  }
  50% {
    transform: translateY(-20px) scale(1.05);
    filter: drop-shadow(0 25px 25px rgba(25, 35, 126, 0.15));
  }
}

.pulse-dots {
  display: flex;
  gap: 0.5rem;
}

.pulse-dot {
  width: 8px;
  height: 8px;
  background-color: #1A237E;
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

.pulse-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.pulse-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(0.8);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
} 