.quicksoap-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 235px);
  box-sizing: border-box;
  transition: 0.5s ease-in-out;
  background: linear-gradient(135deg, #0D1140 0%, #1A237E 65%, #3949AB 100%);
  position: relative;
  overflow: hidden;
  padding: 20px;
  border-radius: 8px;
}

.quicksoap-container::before,
.quicksoap-container::after,
.quicksoap-container .stars-layer {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  pointer-events: none;
}

.quicksoap-container::before {
  background-image: 
    radial-gradient(3px 3px at 10% 10%, rgba(255, 255, 255, 0.95) 100%, transparent),
    radial-gradient(3px 3px at 25% 30%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(3px 3px at 40% 15%, rgba(255, 255, 255, 0.95) 100%, transparent),
    radial-gradient(3px 3px at 55% 25%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(3px 3px at 70% 45%, rgba(255, 255, 255, 0.95) 100%, transparent),
    radial-gradient(3px 3px at 85% 35%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(3px 3px at 15% 55%, rgba(255, 255, 255, 0.95) 100%, transparent),
    radial-gradient(3px 3px at 30% 75%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(3px 3px at 45% 65%, rgba(255, 255, 255, 0.95) 100%, transparent),
    radial-gradient(3px 3px at 60% 85%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(3px 3px at 75% 95%, rgba(255, 255, 255, 0.95) 100%, transparent),
    radial-gradient(3px 3px at 90% 65%, rgba(255, 255, 255, 0.9) 100%, transparent);
  background-size: 200% 200%;
  background-repeat: repeat;
  animation: stars1 45s linear infinite;
  animation-delay: calc(var(--star-delay, 0) * -1s);
  opacity: 0.8;
}

.quicksoap-container::after {
  background-image: 
    radial-gradient(2px 2px at 15% 20%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 30% 40%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 45% 60%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 60% 30%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 75% 50%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 90% 70%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 20% 80%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 35% 90%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 50% 25%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 65% 45%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 80% 65%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2px 2px at 95% 85%, rgba(255, 255, 255, 0.9) 100%, transparent);
  background-size: 200% 200%;
  background-repeat: repeat;
  animation: stars2 60s linear infinite;
  animation-delay: calc(var(--star-delay, 0) * -1.5s);
  opacity: 0.6;
}

.stars-layer {
  background-image: 
    radial-gradient(2.5px 2.5px at 5% 15%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 20% 35%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 35% 55%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 50% 75%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 65% 25%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 80% 45%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 95% 65%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 10% 85%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 25% 95%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 40% 15%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 55% 35%, rgba(255, 255, 255, 0.9) 100%, transparent),
    radial-gradient(2.5px 2.5px at 70% 55%, rgba(255, 255, 255, 0.9) 100%, transparent);
  background-size: 200% 200%;
  background-repeat: repeat;
  animation: stars3 75s linear infinite;
  animation-delay: calc(var(--star-delay, 0) * -2s);
  opacity: 0.7;
}

@keyframes stars1 {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(-50%);
  }
}

@keyframes stars2 {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(-50%);
  }
}

@keyframes stars3 {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(-50%);
  }
}

.top-controls {
  position: relative;
  z-index: 1;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.patient-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  margin-bottom: 12px;
  flex-shrink: 0;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.patient-info {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.patient-name {
  font-size: 24px;
  font-weight: 600;
  color: #CCF1F6;
  line-height: 36px;
}

.pims-id {
  color: #CCF1F6;
  font-size: 16px;
  font-weight: normal;
  line-height: 36px;
}

.separator {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 8px;
}

.time {
  color: #CCF1F6;
  font-size: 16px;
  line-height: 36px;
}

.appointment-type-tag {
  color: rgb(0, 0, 0);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.header-separator {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 16px;
}

.metadata-row {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.metadata-item {
  display: flex;
  gap: 6px;
  align-items: center;
}

.metadata-item .label {
  color: rgba(255, 255, 255, 0.6);
}

.metadata-item .label emoji {
  font-size: 12px;
}

.metadata-item .value {
  color: #CCF1F6;
  font-weight: 500;
}

.separator {
  color: #cbd5e0;
}

.artifacts-row {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  flex-shrink: 0;
  margin-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.artifacts-row::-webkit-scrollbar {
  display: none;
}

.artifacts-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.recording-tag {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.15);
  color: #CCF1F6;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 13px;
}

.delete-recording-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #CCF1F6;
  font-size: 14px;
  width: 16px;
  height: 16px;
  padding: 0;
  margin-left: 4px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.delete-recording-btn:hover {
  opacity: 1;
}

.static-info {
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  min-width: 0;
}

.tag-row {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 0;
}

.tag-row::before,
.tag-row::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #cbd5e0;
}

.tag-row::before {
  top: 0;
}

.tag-row::after {
  bottom: 0;
}

.toggle-container {
  margin-left: 16px;
}

.custom-toggle {
  position: relative;
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  width: 100px;
  height: 36px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.toggle-option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s ease-in-out;
}

.toggle-slider {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #00B8D4;
  border-radius: 4px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 184, 212, 0.3);
}

.toggle-icon {
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.toggle-option.active .toggle-icon {
  color: white;
}

.toggle-option:not(.active) .toggle-icon {
  color: rgba(255, 255, 255, 0.6);
}

.date-navigation {
  display: flex;
  align-items: center; /* Vertically centers the arrows and date */
  justify-content: center; /* Centers everything within the container */
  gap: 1rem; /* Adds space between the arrows and the date */
}

.date-nav-button {
  background: none;
  border: none;
  color: #3b82f6; /* Blue color for the arrow buttons */
  font-size: 1rem;
  padding-top: 5px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.date-nav-button:hover {
  transform: scale(
    1.2
  ); /* Enlarge slightly on hover for better user experience */
}

.medical-scribe-box {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  overflow: visible;
}

.medical-scribe-content {
  position: relative;
  z-index: 1;
  background: none;
}

.recording-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex: 1;
  padding: 20px 0;
  overflow: visible;
}

.kanban-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  height: 100%; /* Ensure it uses full height */
}

.kanban-cards-container {
  overflow-y: auto;
  flex-grow: 1;
  padding: 0; /* Remove padding */
}

.recording-icon {
  font-size: 12px;
  margin-right: 4px;
  color: rgba(255, 255, 255, 0.9);
}

.recording-time {
  font-size: 11px;
  opacity: 0.8;
  margin-left: 4px;
}

.recording-button {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(circle at center, #33C6DC, #00B8D4);
  box-shadow: 
    0 0 40px rgba(0, 184, 212, 0.2),
    0 0 80px rgba(0, 184, 212, 0.1),
    0 0 120px rgba(0, 184, 212, 0.05);
  z-index: 1;
  transition: all 0.8s ease-in-out;
  cursor: pointer;
}

/* Add hover state */
.recording-button:hover:not(.recording) {
  background: radial-gradient(circle at center, #00B8D4, #0097A7);
  box-shadow: 
    0 0 60px rgba(0, 184, 212, 0.3),
    0 0 100px rgba(0, 184, 212, 0.2),
    0 0 140px rgba(0, 184, 212, 0.1);
}

/* Circular glow effect */
.recording-button::before {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(0, 184, 212, 0.3) 0%,
    rgba(0, 184, 212, 0.1) 40%,
    transparent 70%
  );
  z-index: -1;
}

/* Recording state with circular glow */
.recording-button.recording {
  background: radial-gradient(circle at center, #FF6D00, #D50000);
  box-shadow: 
    0 0 40px rgba(213, 0, 0, 0.3),
    0 0 80px rgba(213, 0, 0, 0.2),
    0 0 120px rgba(213, 0, 0, 0.1);
  animation: gentlePulse 3s ease-in-out infinite;
}

@keyframes gentlePulse {
  0% {
    box-shadow: 
      0 0 40px rgba(213, 0, 0, 0.3),
      0 0 80px rgba(213, 0, 0, 0.2),
      0 0 120px rgba(213, 0, 0, 0.1);
  }
  50% {
    box-shadow: 
      0 0 60px rgba(213, 0, 0, 0.4),
      0 0 100px rgba(213, 0, 0, 0.3),
      0 0 140px rgba(213, 0, 0, 0.2);
  }
  100% {
    box-shadow: 
      0 0 40px rgba(213, 0, 0, 0.3),
      0 0 80px rgba(213, 0, 0, 0.2),
      0 0 120px rgba(213, 0, 0, 0.1);
  }
}

.recording-button span {
  color: rgba(255, 255, 255, 0.9);
}

.recording-timer {
  color: #CCF1F6;
  font-size: 20px;
  margin-top: 20px;
}

.audio-visualizer-container {
  background: rgba(13, 17, 64, 0.3);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.microphone-test-button {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #CCF1F6;
  padding: 8px 16px;
  border-radius: 20px;
  transition: all 0.3s ease;
  margin-top: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.microphone-test-button:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.microphone-test-button.testing {
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.3);
  box-shadow: 0 4px 12px rgba(220, 53, 69, 0.15);
}

.generate-note-button {
  background: rgba(59, 130, 246, 0.8);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: white;
  padding: 12px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.generate-note-button:hover {
  background: rgba(59, 130, 246, 0.9);
  border-color: rgba(255, 255, 255, 0.3);
}

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #CCF1F6;
  text-align: center;
  z-index: 1;
  position: relative;
}

.empty-state-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 2rem;
}

.empty-state h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.empty-state p {
  font-size: 1rem;
  opacity: 0.8;
}

.empty-state p {
  font-size: 1rem;
  opacity: 0.8;
}

.empty-state .cheeky-text {
  font-size: 0.875rem;
  color: rgba(204, 241, 246, 0.6);
  margin-top: 1rem;
  font-style: italic;
}