.topic-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
}

.topic-container::-webkit-scrollbar {
  width: 6px;
}

.topic-container::-webkit-scrollbar-track {
  background: transparent;
}

.topic-container::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 3px;
}

.topic-header {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 30px;
  position: relative;
}

.header-image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.topic-header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(1.5px);
  transform: scale(1.02);
  transition: filter 0.3s ease;
}

.topic-content {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.topic-metadata {
  display: flex;
  gap: 20px;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.topic-title {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 30px;
  font-weight: 700;
  line-height: 1.2;
}

.topic-section {
  margin-bottom: 32px;
}

.topic-section h2 {
  font-size: 1.5rem;
  color: #1e293b;
  margin-bottom: 16px;
  font-weight: 600;
}

.topic-section p {
  color: #475569;
  line-height: 1.7;
  margin-bottom: 16px;
}

.topic-footer {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #e2e8f0;
}

.strong-text {
  font-weight: 600;
  color: #2d3748;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .topic-container {
    padding: 10px;
  }

  .topic-content {
    padding: 20px;
  }

  .topic-title {
    font-size: 1.8rem;
  }

  .topic-header {
    height: 200px;
  }
}

/* Add to existing styles */

.external-links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 32px;
}

@media (max-width: 768px) {
  .external-links-grid {
    grid-template-columns: 1fr;
  }
}

/* Add these styles for better content formatting */

.topic-list {
  margin: 16px 0;
  padding-left: 20px;
}

.topic-list li {
  margin-bottom: 12px;
  position: relative;
  padding-left: 16px;
}

.topic-list li::before {
  content: "•";
  color: #3B82F6;
  font-weight: bold;
  position: absolute;
  left: -12px;
} 