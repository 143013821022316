.appointment-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 15px;
}

.appointment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.patient-count {
  font-size: 18px;
  line-height: 1.5;
}

.appointment-schedule {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  padding: 10px;
}

.appointment-schedule-container {
  flex-shrink: 0;
  position: relative;
}

.appointment-type-buttons {
  display: flex;
  gap: 10px;
}

.appointment-type-button {
  font-size: 14px; 
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.appointment-type-button:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.no-reminders-placeholder {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  height: 100px;
  justify-content: center;
}

.reminder-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.reminder-table td,
.reminder-table th {
  border-bottom: 1px solid #e9ecef;
  font-size: 0.9em;
  padding: 8px 12px;
  text-align: left;
}

.reminder-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 10;
}

.reminder-table tr:hover {
  background-color: #f8f9fa;
}

.reminder-table-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 20px;
  overflow: hidden;
  width: 100%;
}

.reminder-table-title {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  color: #333;
  font-size: 1.1em;
  margin: 0;
  padding: 10px 15px;
}

.reminder-table-wrapper {
  max-height: 300px;
  overflow-y: auto;
}

.schedule-slot {
  border-radius: 6px;
  height: 30px;
  margin-right: 5px;
  position: relative;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.schedule-slot:hover,
.schedule-slot.highlight {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border: 2px solid #1A237E;
}

.schedule-slot.blocked {
  background-image: repeating-linear-gradient(
    45deg,
    #e0e0e0,
    #e0e0e0 10px,
    #c0c0c0 10px,
    #c0c0c0 20px
  );
}

.schedule-slot.free-time {
  background-color: #8e8e8e2d;
  border: 1px dashed #a7a7a7;  
  cursor: pointer;
}

.schedule-slot.free-time.selected {
  border: 2px solid #3B82F6;
}

.time-tick {
  color: #666;
  font-size: 12px;
}

.time-ticks {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  z-index: 10;
  white-space: nowrap;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-appointment-form {
  position: relative;
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.add-appointment-form h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.form-fields {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-field {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-field label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-field select,
.form-field input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px; /* Ensure this matches the dropdown font size */
}

.form-field input::placeholder {
  font-size: 14px; /* Match the placeholder font size to the input font size */
  color: #757575; /* Adjust the color to match the dropdown placeholder color */
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #3B82F6;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.submit-btn:hover:not(.disabled) {
  background-color: #2563EB;
}

.submit-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #ff6b6b;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.3s ease, color 0.3s ease;
}

.close-btn:hover {
  opacity: 1;
  color: #ff4757;
}

.schedule-slot {
  position: relative;
  box-sizing: border-box;
}

.add-appointment-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #10B981;
  cursor: pointer;
}

.dynamic-title {
  margin-top: 0;
  margin-bottom: 15px;
  min-height: 24px; /* Prevents layout shift when title appears */
}

.schedule-instruction {
  color: #64748b;
  font-size: 14px;
  margin: 12px 0;
  padding: 0 10px;
  font-style: italic;
}

.multi-appointment {
  position: relative;
  overflow: visible;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 !important;
  gap: 2px;
}

.appointment-sub-block {
  width: 100%;
  min-height: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  overflow: visible;
}

.appointment-sub-block .tooltip {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  color: #333;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.appointment-sub-block .tooltip::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px 6px 0;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.appointment-sub-block:hover {
  z-index: 4;
}

.multi-appointment:hover .multi-appointment-indicator {
  display: none;
}

.unassigned-appointments {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.unassigned-appointment-btn {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.unassigned-appointment-btn:hover {
  background-color: #e0e0e0;
}

.current-block {
  position: relative;
}

.current-block-indicator {
  position: absolute;
  bottom: -14px; /* Adjust this value to position the triangle below */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #3B82F6; /* Color of the triangle */
  z-index: 3;
}

@keyframes starFloat {
  0% {
    transform: translateY(-15px) rotate(0deg);
    opacity: 0.9;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-20px) rotate(15deg);
    opacity: 0.9;
  }
}

.star-decoration {
  pointer-events: none;
  top: 0;
  z-index: 3;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.8);
}

/* Optional: Add this if you want the stars to appear with a fade-in effect */
.highlight .star-decoration {
  animation-name: starFloat;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* Add these styles to ensure dropdowns appear above other elements */
.add-appointment-form .form-field {
  position: relative;
  z-index: 20; /* Higher than surrounding elements */
}

/* Ensure the Select dropdown menu appears above everything */
div[class*="react-select__menu"] {
  z-index: 30 !important; /* Force higher z-index for dropdown options */
}

.current-datetime {
  display: flex;
  gap: 12px;
  align-items: baseline;
}

.current-datetime .date {
  font-size: 20px;
  font-weight: 500;
  color: #1A237E;
}

.current-datetime .time {
  font-size: 20px;
  font-weight: 500;
  color: #00B8D4;  /* or any color that matches your theme */
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.schedule-instruction {
  color: #64748b;
  font-size: 14px;
  font-style: italic;
  text-align: center;
  flex: 1;
  margin: 0 20px;
}

.current-datetime {
  display: flex;
  gap: 12px;
  align-items: baseline;
  flex-shrink: 0; /* Prevent datetime from shrinking */
}

.appointment-type-buttons {
  display: flex;
  gap: 10px;
  flex-shrink: 0; /* Prevent buttons from shrinking */
}

.time-selectors {
  display: flex;
  gap: 1rem;
}

.time-selectors .form-field,
.form-field.duration-field {
  width: 200px;  /* Set explicit width */
  flex: none;    /* Prevent flex from affecting width */
}

/* Ensure the Select components take full width of their container */
.time-selectors .form-field > div,
.form-field.duration-field > div {
  width: 100%;
}
