.account-settings-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* Spacey Background */
.stars-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #1A237E, #00B8D4);
  z-index: -1;
}

@keyframes twinkle {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.stars, .stars2, .stars3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  animation: twinkle 2s infinite;
}

.stars {
  background-image: radial-gradient(1px 1px at 50% 50%, white, rgba(0,0,0,0));
  background-size: 100px 100px;
}

.stars2 {
  background-image: radial-gradient(2px 2px at 50% 50%, rgba(255,255,255,0.5), rgba(0,0,0,0));
  background-size: 200px 200px;
  animation-delay: 0.5s;
}

.stars3 {
  background-image: radial-gradient(3px 3px at 50% 50%, rgba(255,255,255,0.3), rgba(0,0,0,0));
  background-size: 300px 300px;
  animation-delay: 1s;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  z-index: 1;
}

.settings-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 1.5rem;
}

.settings-card {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.settings-card:hover {
  transform: translateY(-2px);
}

.settings-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.setting-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.25rem;
  padding: 0.75rem;
  background: rgba(247, 248, 249, 0.7);
  border-radius: 0.5rem;
}

.setting-label {
  color: #4a5568;
  font-size: 0.875rem;
}

.setting-value {
  color: #2d3748;
  font-weight: 500;
}

.security-button {
  width: 100%;
  text-align: left;
  padding: 1rem;
  background: rgba(247, 248, 249, 0.7);
  border-radius: 0.5rem;
  transition: background-color 0.2s ease;
}

.security-button:hover {
  background: rgba(237, 242, 247, 0.9);
}

.status-badge {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  background: rgba(203, 213, 224, 0.3);
  border-radius: 1rem;
  color: #4a5568;
}

.preference-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.session-info {
  color: #4a5568;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Add new styles for Clinic Team section */
.clinic-team-section {
  height: calc(100% - 1.5rem);  /* Adjust for the margin-bottom of settings-card */
  display: flex;
  flex-direction: column;
}

.team-members {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.team-member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: rgb(66, 153, 225, 0.2);  /* Light grey background with some transparency */
  border-radius: 0.5rem;
  transition: transform 0.2s ease;
}

.team-member:hover {
  transform: translateX(4px);
  background: rgba(66, 153, 225, 0.4);  /* Slightly darker on hover */
}

.member-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.member-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.member-name {
  font-weight: 500;
  color: #2d3748;
}

.member-role {
  font-size: 0.875rem;
  color: #4a5568;
}

.status-badge.online {
  background-color: rgba(72, 187, 120, 0.2);
  color: #2f855a;
}

.status-badge.offline {
  background-color: rgba(160, 174, 192, 0.2);
  color: #4a5568;
}

.team-members-container {
  flex: 1;  /* This will make it take up available space */
  max-height: 470px;  /* Increased from 400px to 600px */
  overflow-y: auto;
  margin-bottom: 1rem;
}

.team-members-container::-webkit-scrollbar {
  width: 8px;
}

.team-members-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.team-members-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.team-members-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

.team-members {
  padding-right: 8px; /* Add some space for the scrollbar */
}

.add-member-button {
  width: 100%;
  padding: 0.75rem;
  background: rgba(66, 153, 225, 0.1);
  border: 2px dashed rgba(66, 153, 225, 0.3);
  border-radius: 0.5rem;
  color: rgb(66, 153, 225);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  margin-top: auto;  /* This pushes the button to the bottom */
}

.add-member-button:hover {
  background: rgba(66, 153, 225, 0.2);
  border-color: rgba(66, 153, 225, 0.4);
}

.plus-icon {
  font-size: 1.25rem;
  font-weight: bold;
}

/* Update responsive design */
@media (max-width: 768px) {
  .settings-grid {
    grid-template-columns: 1fr;
  }
  
  .clinic-team-section {
    height: auto;
  }
}

@media (max-width: 640px) {
  .settings-items {
    grid-template-columns: 1fr;
  }
}

.setting-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #f8fafc;
  color: #2d3748;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.setting-input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
}

.setting-input[readonly] {
  background-color: #edf2f7;
  cursor: default;
}

.button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;
}

.button-secondary {
  background-color: #f3f4f6;
  color: #4b5563;
  border: none;
}

.button-secondary:hover {
  background-color: #e5e7eb;
}

.button-primary {
  background-color: #3B82F6;
  color: white;
  border: none;
}

.button-primary:hover {
  background-color: #2563eb;
}

.team-column {
  display: flex;
  flex-direction: column;
}

.team-members {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.member-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.member-name {
  font-weight: 500;
  color: #2d3748;
}

.member-role {
  font-size: 0.875rem;
  color: #4a5568;
}

.member-email {
  font-size: 0.875rem;
  color: #4a5568;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
}

.invite-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #4a5568;
}

.form-group input,
.form-group select {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.home-button {
  display: flex;
  align-items: center;
  position: relative;
  top: -10px;
  left: -10px;
  background: #3B82F6;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s;
  width: fit-content;
  z-index: 2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-button:hover {
  background: #2563eb;
  transform: translateY(-2px);
}

/* Adjust the first settings card to account for the button */
.settings-column > .settings-card:first-of-type {
  margin-top: -20px;
  padding-top: 2.5rem;
}

.round-home-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  transition: all 0.2s;
}

.round-home-button svg {
  color: #1A237E;  /* Dark blue color */
  width: 20px;
  height: 20px;
}

.round-home-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.generated-email {
  width: 100%;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #f8fafc;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  min-height: 430px;  /* Make it taller */
  margin-bottom: 1rem;
}

.generated-email:focus {
  outline: none;
  border-color: #3B82F6;
  ring: 2px solid #3B82F6;
}

.generated-email-container {
  width: 100%;
}

.modal-subtext {
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  line-height: 1.25;
}

.subscription-section .button-primary {
  width: fit-content;  /* Make button only as wide as its content */
  margin: 0 auto;     /* Center the button */
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  display: block;      /* Needed for margin auto to work */
}

.subscription-section .button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.subscription-section .button {
  flex: 1;  /* Make both buttons take equal space */
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-secondary.button-danger {
  background-color: rgba(254, 226, 226, 0.7);  /* Light red background */
  color: rgb(220, 38, 38);  /* Red text */
}

.button-secondary.button-danger:hover {
  background-color: rgba(254, 202, 202, 0.9);  /* Slightly darker on hover */
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}