.slots-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.time-slot {
  border-radius: 4px;
  margin: 5px 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  font-size: 14px;
  padding: 10px;
  position: relative;
  transition: opacity 0.3s ease;
}

.timeslot-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

.timeslot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.timeslot-time {
  font-weight: bold;
}

.timeslot-buttons {
  display: flex;
  align-items: center;
}

.timeslot-button {
  font-size: small;
  margin-left: 10px;
  text-decoration: none;
  color: #333;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  background-color: transparent;
}

.timeslot-button:hover {
  background-color: #e2e8f0;
  color: #2c5282;
}

.reminders-toggle {
  margin-left: 0; /* Remove left margin for the first button */
}

.timeslot-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.timeslot-info span {
  display: flex;
  align-items: center;
}

.timeslot-summary {
  flex-grow: 1;
  margin-top: 5px;
  word-wrap: break-word;
}

.category-strip {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: opacity 0.3s ease;
}

.alert-icon {
  margin-left: 5px;
  cursor: pointer;
  position: relative;
}

.alert-tooltip {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin-top: 5px;
}

.alert-tooltip::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.alert-icon:hover .alert-tooltip {
  opacity: 1;
}

.icon-circle {
  display: inline-block;
  background-color: #F43F5E;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
  line-height: 1;
}

.birthday-icon.birthday-soon {
  position: relative;
  color: #ffa500;
  animation: glow 1.5s infinite alternate ease-in-out;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px rgba(255, 165, 0, 0.5);
  }
  to {
    text-shadow: 0 0 20px rgba(255, 165, 0, 1);
  }
}

.birthday-highlight {
  background-color: rgba(255, 245, 157, 0.5);
  border-radius: 4px;
  padding: 2px 4px;
  transition: background-color 0.3s ease;
}

.reminder-table-container {
  margin-top: 10px;
  height: calc(4 * 1.5em + 3 * 8px + 2.5em); /* Fixed height */
  overflow: hidden;
  position: relative;
}

.reminder-table-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enables scrolling */
}

.reminder-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.reminder-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.reminder-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 8px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.reminder-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.reminder-table tbody tr:last-child td {
  border-bottom: none;
}

.no-reminders-placeholder,
.no-medications-placeholder {
  text-align: center;
  padding: 16px;
  color: #666;
  font-style: italic;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* Add these new styles at the end of the file */

.time-slot.unassigned {
  border-left: 5px solid #ccc;
}

.time-slot.unassigned .timeslot-time {
  font-style: italic;
}

.unassigned-header {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.button-separator {
  width: 1px;
  height: 20px;
  background-color: #ccc;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
}

.appointment-separator {
  border: none;
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

.soap-check-icon {
  margin-left: 4px;
  font-size: 12px;
  color: #4caf50; /* Green color for the checkmark */
}

.soap-button:has(.soap-check-icon) {
  background-color: #e8f5e9; /* Light green background for completed notes */
}

/* Patient Details Section */
.patient-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  height: 100%;
}

.patient-details .header {
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.patient-details .content-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 
    "primer"
    "meds-reminders"
    "fun-fact";
  gap: 20px;
  flex-grow: 1;
}

.patient-details .primer-section {
  grid-area: primer;
}

.patient-details .meds-reminders-container {
  grid-area: meds-reminders;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.patient-details .fun-fact {
  grid-area: fun-fact;
}

.patient-details .primer-section,
.patient-details .current-meds,
.patient-details .reminders,
.patient-details .fun-fact {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.patient-details .section-title {
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}

.patient-details .no-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #666;
  font-style: italic;
}
