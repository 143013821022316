.primer-settings {
    width: 100%;
  }
  
  .appointment-types-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 16px;
    position: relative;
  }
  
  .appointment-type-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #eaeaea;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .appointment-type-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #eaeaea;
  }
  
  .color-picker-container {
    position: relative;
    margin-right: 12px;
  }
  
  .color-picker {
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .color-picker::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
  .color-picker::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
  
  .type-name {
    font-weight: 600;
    color: #1a1a1a;
    font-size: 1rem;
  }
  
  .sub-types-container {
    flex: 1;
    min-height: 50px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .sub-type-item {
    background-color: white;
    padding: 8px 12px;
    margin-bottom: 8px;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #4b5563;
    border: 1px solid #eaeaea;
    cursor: grab;
    transition: all 0.2s ease;
    user-select: none;
  }
  
  .sub-type-item:hover {
    background-color: #f3f4f6;
    transform: translateY(-1px);
  }
  
  .sub-type-item:active {
    cursor: grabbing;
  }
  
  .appointment-type-container.dragging-over {
    background-color: #f0f9ff;
    border: 2px dashed #3B82F6;
  }
  
  .sub-type-item.dragging {
    background-color: #ffffff;
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  }
  
  .settings-description {
    padding: 1rem;
    margin-bottom: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 12px;
  }
  
  .settings-description h3 {
    color: #2d3748;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  
  .settings-description p {
    margin: 0;
    padding: 0;
    color: #4a5568;
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
  
  .instruction-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .instruction-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #718096;
    font-size: 1rem;
  }
  
  .instruction-item .icon {
    font-size: 1.25rem;
    min-width: 24px;
  }
  
  .card {
    padding: 1.5rem;
    background: white;
    border-radius: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .settings-guide {
    padding: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eee;
  }
  
  .guide-container {
    max-width: 300px;
    margin: 0 auto;
  }
  
  .guide-item {
    position: relative;
  }
  
  .guide-visual {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
    border: 2px dashed #ddd;
  }
  
  .demo-type-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .demo-color-picker {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: linear-gradient(45deg, #ff6b6b, #ff8787);
    cursor: pointer;
    position: relative;
  }
  
  .demo-color-picker::after {
    content: "👆";
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .demo-subtypes {
    padding: 0.5rem;
  }
  
  .demo-subtype {
    background: white;
    padding: 0.5rem;
    border-radius: 4px;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .guide-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: #666;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    font-style: italic;
  }
  
  .card-header {
    margin-bottom: 1.5rem;
  }
  
  .card-header h3 {
    margin-bottom: 0.5rem;
  }
  
  .guide-container {
    margin-top: 0.25rem;
  }
  
  .simple-guide {
    color: #666;
    font-size: 0.9rem;
    margin: 0;
  }
  
  .add-subtype-button {
    margin-top: 8px;
    padding: 8px 12px;
    background-color: #f3f4f6;
    border: 1px dashed #d1d5db;
    border-radius: 4px;
    color: #6b7280;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    width: 100%;
  }
  
  .add-subtype-button:hover {
    background-color: #e5e7eb;
    color: #4b5563;
  }
  
  .dragging {
    position: fixed;
    z-index: 1000;
    pointer-events: none;
  }