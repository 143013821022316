body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

.account-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.account-popup-overlay.open {
  opacity: 1;
  visibility: visible;
}

.account-popup {
  background-color: #ffffff;
  height: 100vh;
  width: 550px;
  padding: 0;
  position: relative;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  box-shadow: -5px 0 25px rgba(0, 0, 0, 0.15);
}

.account-popup.open {
  transform: translateX(0);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  border-top-left-radius: 16px;
}

.header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
}

.button-group {
  display: flex;
  gap: 8px;
}

.settings-nav {
  padding: 0 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
}

.settings-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 32px;
}

.settings-nav li {
  padding: 16px 0;
  cursor: pointer;
  color: #666;
  position: relative;
  font-weight: 500;
  transition: all 0.2s ease;
}

.settings-nav li:hover {
  color: #3B82F6;
}

.settings-nav li.active {
  color: #3B82F6;
  font-weight: 600;
}

.settings-nav li.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #3B82F6;
  border-radius: 2px;
}

.settings-content {
  flex: 1;
  overflow-y: auto;
  padding: 24px 30px;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
}

.settings-content::-webkit-scrollbar {
  width: 6px;
}

.settings-content::-webkit-scrollbar-track {
  background: transparent;
}

.settings-content::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 3px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.card h3 {
  margin: 0 0 20px 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a1a1a;
}

.info-item {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 14px 16px;
  border-radius: 8px;
  margin-bottom: 12px;
}

.info-item label {
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
  min-width: 140px;
}

.info-item p {
  margin: 0;
  color: #1a1a1a;
  font-size: 0.95rem;
}

.info-item input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.info-item input:focus {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background-color: #ffffff;
}

.footer {
  padding: 20px 30px;
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
  margin-top: auto;
  border-bottom-left-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.action-link {
  color: #4b5563;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease;
}

.action-link:hover {
  color: #3B82F6;
}

.button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button-primary {
  background-color: #3B82F6;
  color: white;
  box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
}

.button-primary:hover {
  background-color: #2563eb;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.25);
}

.button-secondary {
  background-color: #f3f4f6;
  color: #4b5563;
}

.button-secondary:hover {
  background-color: #e5e7eb;
}

.settings-close-button {
  padding: 8px 16px;
  font-size: 0.9rem;
  background-color: #f3f4f6;
  color: #4b5563;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.settings-close-button:hover {
  background-color: #e5e7eb;
  color: #dc2626;
}

.settings-close-button:active {
  color: #b91c1c;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a1a1a;
}

.card-header .button {
  margin-left: 16px;
}

.resize-handle {
  position: absolute;
  left: -4px;
  top: 0;
  width: 8px;
  height: 100%;
  cursor: ew-resize;
  background: transparent;
}

.resize-handle:hover {
  background: rgba(59, 130, 246, 0.1);
}

.resize-handle:active {
  background: rgba(59, 130, 246, 0.2);
}

.account-popup.dragging {
  user-select: none;
}

.save-message {
  margin-top: 10px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
}

.save-message.success {
  background-color: #e6ffe6;
  color: #006600;
  border: 1px solid #99ff99;
}

.save-message.error {
  background-color: #ffe6e6;
  color: #cc0000;
  border: 1px solid #ff9999;
}