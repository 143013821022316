.medical-scribe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 120px);
  pointer-events: none;
  box-sizing: border-box;
}

.medical-scribe-container.show {
  opacity: 1;
  pointer-events: auto;
}

.medical-scribe-box {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 20px;
  flex-grow: 1;
  max-height: 100%;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.medical-scribe-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.recording-button {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #3B82F6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0.25rem;
}

.recording-button.recording {
  background-color: #dc3545;
  width: 200px;
  height: 200px;
}

.recording-button.generating {
  background-color: transparent;
  border: 2px solid #fbbf24;
  position: relative;
  overflow: hidden;
  cursor: not-allowed;
  opacity: 0.7;
}

.recording-button.generating::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: linear-gradient(to top, #fbbf24, #fcd34d);
  transition: height 0.3s ease-in-out;
  animation: fillLiquid 2s ease-in-out infinite;
  z-index: 1;
}

.recording-button.generating .centered-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.recording-button.generating .progress-text {
  color: #fbbf24;
  font-size: 14px;
  font-weight: 500;
}

.recording-button.generating .loading-icon {
  color: #fbbf24;
  transition: color 0.3s ease;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.recording-icon {
  font-size: 44px;
  color: white;
  margin-bottom: 10px;
  transition: font-size 0.3s ease-in-out;
}

.recording-button.recording .recording-icon {
  font-size: 44px;
}

.loading-icon {
  font-size: 44px;
  color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.recording-button span {
  font-size: 16px;
  font-weight: bold;
  color: white;
  transition: font-size 0.3s ease-in-out;
  text-align: center;
  display: block;
}

.recording-button.recording span {
  font-size: 18px;
}

.recording-timer {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.recording-timer.show {
  opacity: 1;
}

.trash-icon-container {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(-10px);
  z-index: 20;
}

.trash-icon-container.show {
  opacity: 1;
  transform: translateY(0);
}

.trash-icon-container:hover {
  background-color: rgba(191, 191, 191, 0.5);
}

.trash-icon {
  color: #dc3545;
  font-size: 20px;
}

.written-mode-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: none;
  border-radius: 5px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.written-mode-container.generating .written-mode-input {
  opacity: 0.5;
  pointer-events: none;
}

.written-mode-input {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: white;
  font-size: 15px;
  line-height: 1.5;
  resize: none;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.written-mode-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}

.written-mode-input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
}

.written-mode-input::-webkit-scrollbar {
  display: none;
}

.written-mode-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.loading-text {
  color: #fbbf24;
  font-size: 14px;
  font-weight: 500;
}

.control-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  opacity: 1;
  max-height: none;
}

.additional-details-container {
  width: 100%;
  margin-bottom: 16px;
}

.additional-details-input {
  width: 100%;
  min-height: 80px;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: white;
  font-size: 15px;
  line-height: 1.5;
  resize: none;
  transition: all 0.3s ease;
  margin-bottom: 16px;
}

.additional-details-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}

.additional-details-input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
}

.generate-note-button {
  width: 100%;
  padding: 16px;
  background: radial-gradient(circle at center, #33C6DC, #00B8D4);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 0 40px rgba(0, 184, 212, 0.2);
  position: relative;
  overflow: hidden;
}

.generate-note-button:hover:not(:disabled) {
  background: radial-gradient(circle at center, #00B8D4, #0097A7);
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 60px rgba(0, 184, 212, 0.3);
}

.generate-note-button:disabled {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  box-shadow: none;
}

.generate-note-button.loading {
  position: relative;
  overflow: hidden;
}

.generate-note-button .button-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  position: relative;
  z-index: 2;
}

.generate-note-button.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--progress, 0%);
  background: rgba(255, 255, 255, 0.2);
  transition: width 0.3s ease-out;
  z-index: 1;
}

.toggle-inputs-button {
  width: 100%;
  padding: 10px;
  background-color: #3B82F6;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  transition: background-color 0.3s ease-in-out;
}

.toggle-inputs-button:hover {
  background-color: #2563EB;
}

.toggle-inputs-button svg {
  margin-right: 10px;
}

.generated-soap-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: none;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
}

.generated-soap-header {
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  margin-bottom: 12px;
}

.generated-soap-header h3 {
  margin: 0;
  color: #CCF1F6;
  font-size: 15px;
  font-weight: 500;
}

.save-button-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.generated-soap-content {
  position: relative;
  flex-grow: 1;
  padding: 0;
  margin-bottom: 12px;
}

.generated-soap-note {
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: white;
  font-size: 15px;
  line-height: 1.5;
  resize: none;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.generated-soap-note:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
}

.generated-soap-controls {
  display: flex;
  justify-content: stretch;
  gap: 12px;
  padding: 0;
}

.save-button,
.copy-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
}

.save-button {
  background: radial-gradient(circle at center, #33DC76, #00D45F);
  color: white;
}

.save-button:hover:not(:disabled) {
  background: radial-gradient(circle at center, #00D45F, #00B050);
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px rgba(0, 212, 95, 0.3);
}

.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  width: 100%;
  background: radial-gradient(circle at center, #33C6DC, #00B8D4);
  color: white;
}

.copy-button:hover {
  background: radial-gradient(circle at center, #00B8D4, #0097A7);
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px rgba(0, 184, 212, 0.3);
}

.save-button:disabled,
.copy-button:disabled {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  box-shadow: none;
}

.save-success {
  color: #33DC76;
  font-size: 14px;
  font-weight: 500;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.microphone-test-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.microphone-test-button {
  padding: 6px 12px;
  border: none;
  background: none;
  color: #4285f4;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 0.875rem;
  margin: 0.25rem 0;
  font-weight: 400;
}

.microphone-test-button.testing {
  color: #dc3545;
  font-weight: 500;
}

.microphone-test-button:hover {
  opacity: 0.8;
}

.audio-visualizer-container {
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 8px;
  margin: 0.25rem auto;
}

.microphone-warning {
  color: #f44336;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  text-align: center;
}

.recording-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.audio-visualizer-container {
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 8px;
  margin: 0.25rem auto;
}

.note-title-container {
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}

.note-title-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
}

.note-title-input::placeholder {
  color: #999;
  font-style: italic;
}

.note-title-input:focus {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.recording-button:not(.recording) .recording-icon {
  font-size: 44px;
  margin-bottom: 10px;
}

.save-indicator {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #CCF1F6;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.save-indicator.saving {
  opacity: 0.8;
  transform: translateY(0);
}

.save-indicator.saved {
  opacity: 0.8;
  transform: translateY(0);
  background: rgba(51, 220, 118, 0.1);
  border-color: rgba(51, 220, 118, 0.2);
  color: #33DC76;
}

.note-type-toggle {
  display: flex;
  width: 100%;
  gap: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
}

.toggle-button {
  flex: 1;
  padding: 12px 16px;
  border: none;
  background: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
  z-index: 1;
}

.toggle-button.active {
  color: #CCF1F6;
  background: none;
}

.note-type-toggle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 12px;
}

.note-type-toggle[data-active="discharge"]::after {
  transform: translateX(100%);
}

.header-controls {
  position: absolute;
  top: 3.3rem;
  right: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;
}

.new-note-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 100px;
  padding: 0 1rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  white-space: nowrap;
}

.new-note-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
}

.loading-icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  font-size: 44px;
  color: #fbbf24;
  animation: spin 1s linear infinite;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fbbf24;
  font-size: 44px;
  font-weight: 700;
}