.primer-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 140px);
    overflow: hidden;
    padding: 0 20px;
}
  
  .blank-container {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: #f8fafc;
  }
  
  .blank-container::-webkit-scrollbar {
    display: none;
  }
  
  @media (min-width: 650px) {
    .blank-container {
        flex-direction: row !important;
    }

    .patient-list-container {
        width: 400px;
    }

    .patient-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 20px !important;
    }

    .slots-container {
        display: none !important;
    }
  }
  
  @media (max-width: 649px) {
    .patient-list, .patient-details {
        display: none !important;
    }
  }

  .patient-list-item {
    display: flex;
    margin-bottom: 8px;
    position: relative;
    height: 70px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.patient-list-item .category-strip {
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.patient-list-item .patient-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #f8fafc;
    margin-left: 4px;
    border-radius: 4px;
    height: 100%;
    padding-left: 20px;
    transition: all 0.2s ease;
    border: 1px solid #e2e8f0;
}

/* Hover state - Cosmic Cyan 40 */
.patient-list-item:hover .patient-info {
    background-color: #f0f7ff;
    border-color: #bfdbfe;
}

/* Selected state - Cosmic Cyan 20 */
.patient-list-item.selected .patient-info {
    background-color: #CCF1F6;
    border-color: #67e8f9;
}

.patient-list-item .patient-name {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #1e293b;
}

.patient-list-item .patient-time {
    font-size: 14px;
    color: #64748b;
    margin-top: 4px;
}

.no-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #666;
    font-style: italic;
    font-size: 16px;
    padding: 20px;
}

.header-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.header-info {
    flex: 1;
}

.header-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
}

.patient-metadata {
    display: flex;
    gap: 24px;
    align-items: center;
    color: #666;
    font-size: 16px;
}

.metadata-item {
    display: flex;
    align-items: center;
    gap: 6px;
}

.header-buttons {
    display: flex;
    gap: 12px;
    margin-left: 24px;
    background: none !important;
    background-color: transparent !important;
    padding: 0;
}

.header-button {
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: transparent;
    cursor: pointer;
    white-space: nowrap;
}

.header-button.soap-button {
    position: relative;
}

.soap-check-icon {
    color: #4caf50;
    font-size: 18px;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: white;
    border-radius: 50%;
    padding: 3px;
}

.alert-section {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    background-color: #fee2e2;
    border-radius: 6px;
    border: 1px solid #fecaca;
}

.alert-section .alert-icon {
    width: 24px;
    height: 24px;
    color: #dc2626;
    flex-shrink: 0;
}

.alert-section .alert-text {
    color: #991b1b;
    font-size: 14px;
    line-height: 1.4;
}

.alerts-wrapper {
    margin-top: 12px;
    width: 100%;
}

.content-section {
    background-color: white;
    padding: 20px;
    margin: 0;
    border-radius: 0;
    height: fit-content;
}

/* Markdown content styles */
.content-section p {
    margin: 0;  /* Remove default paragraph margins */
    line-height: 1.6;
}

/* Add spacing only between paragraphs */
.content-section p + p {
    margin-top: 1rem;
}

/* Style for the Fun Fact section */
.content-section p:last-child {
    margin-top: 1rem;  /* Space above Fun Fact */
    margin-bottom: 0;  /* No space below */
}

.content-section h1,
.content-section h2,
.content-section h3,
.content-section h4 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.content-section h1 {
    font-size: 1.5rem;
}

.content-section h2 {
    font-size: 1.25rem;
}

.content-section h3 {
    font-size: 1.125rem;
}

.content-section ul,
.content-section ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

.content-section li {
    margin-bottom: 0.5rem;
}

.content-section a {
    color: #0284c7;
    text-decoration: underline;
}

.content-section a:hover {
    color: #0369a1;
}

.content-section code {
    background-color: #f1f5f9;
    padding: 0.2rem 0.4rem;
    border-radius: 0.25rem;
    font-family: monospace;
}

.content-section blockquote {
    border-left: 4px solid #e2e8f0;
    padding-left: 1rem;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    color: #64748b;
}

.primer-section,
.medications-section,
.reminders-section,
.fun-fact-section {
    box-shadow: none;
    border-radius: 8px;
    margin: 0;
    padding: 0;
    height: fit-content;
}

.content-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    flex: 1;
}

.primer-section {
    grid-column: 1 / -1;
    margin: 0;
    padding: 0;
    height: fit-content;
}

.patient-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-left: 20px;
}

.details-container {
    display: flex;
    gap: 10px;
    flex: 1;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
}

.details-main {
    flex: 0 0 calc(70% - 5px);
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.details-tasks {
    flex: 0 0 calc(30% - 5px);
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.header {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.birthday-icon.birthday-soon {
  position: relative;
  color: #ffa500;
  animation: glow 1.5s infinite alternate ease-in-out;
}

.birthday-tooltip {
  position: absolute;
  background-color: white;
  color: #444;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600 !important;
  white-space: nowrap;
  z-index: 999;
  opacity: 1;
  pointer-events: none;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
}

.birthday-tooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -6px;
  transform: translateY(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: white;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px rgba(255, 165, 0, 0.5);
  }
  to {
    text-shadow: 0 0 20px rgba(255, 165, 0, 1);
  }
}

.metadata-item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Add this new style for the reminders metadata */
.metadata-item:has(.reminder-count) {
  font-weight: 500;
}

/* Make reminders text bolder */
.metadata-item:last-child {
  font-weight: 600;
  color: #444;
}

.patient-list-container {
  width: 400px;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.patient-list-header {
  background-color: white;
  border-radius: 8px 8px 0 0;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-patient-button {
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 8px 12px;
  color: #1e293b;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-patient-button:hover {
  background-color: #f0f7ff;
  border-color: #bfdbfe;
}

.patient-count {
  font-size: 18px;
  font-weight: 600;
  color: #1e293b;
}

.patient-list {
  flex: 1;
  overflow-y: auto;
  background-color: white;
  border-radius: 0 0 8px 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Update the container background */
.blank-container {
  background-color: #f8fafc;
}

.appointment-group {
  margin-bottom: 1.5rem;
}

.appointment-group:last-child {
  margin-bottom: 0;
}

.appointment-group-header {
  padding: 12px 16px;
  font-size: 0.875rem;
  color: #666;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 8px;
  font-weight: 500;
  margin-bottom: 8px;
}

/* Reset the top margin on first item since we're using header margin instead */
.appointment-group .patient-list-item:first-child {
  margin-top: 0;
}

.appointment-group .patient-list-item:last-child {
  margin-bottom: 8px;
}

.appointment-group .patient-list-item {
  margin-left: 8px;
  margin-right: 8px;
  width: calc(100% - 16px);
}

.appointment-block {
  position: absolute;
  left: calc((var(--start-position) / 720) * 100%);
  width: calc((var(--width) / 720) * 100%);
}

/* Add these new styles */
.tables-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 16px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.medications-section {
  margin-top: 0;
}

.medication-table-container {
  height: auto !important;
  max-height: none !important;
  overflow: visible !important;
}

.medication-table-wrapper {
  border: 1px solid #eee;
  border-radius: 4px;
  overflow: visible !important;
  height: auto !important;
  max-height: none !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.medication-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.medication-table th,
.medication-table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.medication-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #444;
}

.medication-table tr:last-child td {
  border-bottom: none;
}

.no-medications-placeholder {
  padding: 16px;
  text-align: center;
  color: #666;
  font-style: italic;
}

/* Common table styles */
.reminder-table,
.medication-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.reminder-table th,
.medication-table th,
.reminder-table td,
.medication-table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.reminder-table th,
.medication-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #444;
}

.reminder-table tr:last-child td,
.medication-table tr:last-child td {
  border-bottom: none;
}

/* Common placeholder styles */
.no-reminders-placeholder,
.no-medications-placeholder {
  padding: 16px;
  text-align: center;
  color: #666;
  font-style: italic;
}

/* Common table wrapper styles */
.reminder-table-wrapper,
.medication-table-wrapper {
  border: 1px solid #eee;
  border-radius: 4px;
  overflow: visible !important;
  height: auto !important;
  max-height: none !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Common table container styles */
.reminder-table-container,
.medication-table-container {
  height: auto !important;
  max-height: none !important;
  overflow: visible !important;
}

/* Add these new styles for the primer content */
.primer-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.primer-text {
  line-height: 1.6;
  color: #1e293b;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 16px;
}

.fun-fact-section {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: #f0fdf4;
  border-left: 3px solid #86efac;
  border-radius: 4px;
  margin-bottom: 16px;
}

.fun-fact-icon {
  color: #166534;
  font-size: 14px;
}

.fun-fact-text {
  color: #166534;
  font-size: 14px;
  font-style: italic;
  line-height: 1.4;
}

/* Update the content section styles */
.content-section {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  height: fit-content;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 0;
}

.tables-section {
  margin-top: 16px;
  padding-top: 20px;
  border-top: 1px solid #e2e8f0;
}

/* Remove the primer section title since it's redundant */
.primer-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Update the primer section styles */
.primer-section {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.primer-text {
  line-height: 1.6;
  color: #1e293b;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}