.react-datepicker {
  font-family: inherit !important;
  border: none !important;
  background-color: white !important;
  box-shadow: 0 4px 20px -2px rgb(0 0 0 / 0.1) !important;
  border-radius: 1rem !important;
  padding: 1rem !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: stretch !important;
}

.react-datepicker__month-container {
  padding-right: 1rem !important;
  display: flex;
  flex-direction: column;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
  padding-top: 0 !important;
  flex-shrink: 0;
}

.react-datepicker__time-container {
  border-left: 1px solid #e5e7eb !important;
  width: 110px !important;
  display: flex !important;
  flex-direction: column !important;
}

.react-datepicker__time {
  flex-grow: 1 !important;
  background: white !important;
}

.react-datepicker__time-box {
  height: 100% !important;
  width: 100% !important;
  overflow-y: auto !important;
}

.react-datepicker__current-month {
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-bottom: 0.5rem !important;
}

.react-datepicker__day-name {
  color: #6b7280 !important;
  font-weight: 500 !important;
  margin: 0.5rem !important;
}

.react-datepicker__day {
  margin: 0.5rem !important;
  width: 2rem !important;
  line-height: 2rem !important;
  border-radius: 0.5rem !important;
  color: #374151 !important;
}

.react-datepicker__day:hover {
  background-color: #f3f4f6 !important;
  border-radius: 0.5rem !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #0ea5e9 !important;
  color: white !important;
  border-radius: 0.5rem !important;
  font-weight: 600 !important;
}

.react-datepicker__time-list-item {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 0.875rem !important;
  color: #374151 !important;
}

.react-datepicker__time-list-item:hover {
  background-color: #f3f4f6 !important;
}

.react-datepicker__time-list-item--selected {
  background-color: #0ea5e9 !important;
  color: white !important;
  font-weight: 600 !important;
}

.react-datepicker__navigation {
  top: 0.5rem !important;
  z-index: 1 !important;
}

.react-datepicker__navigation--previous {
  left: 1rem !important;
}

.react-datepicker__navigation--next {
  right: calc(110px + 2rem) !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #6b7280 !important;
  border-width: 2px 2px 0 0 !important;
  height: 8px !important;
  width: 8px !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

/* Custom scrollbar for time list */
.react-datepicker__time-list::-webkit-scrollbar {
  width: 6px;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 3px;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
