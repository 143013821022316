.primer-settings {
  width: 100%;
}

.card {
  padding: 1.5rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.settings-section {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
}

.settings-section:last-child {
  margin-bottom: 0;
}

.settings-section h3 {
  color: #2d3748;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.template-toggle {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  transition: .3s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .3s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.custom-template {
  margin-top: 40px;
  width: 100%;
  position: relative;
  text-align: left;
}

.custom-template::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #e0e0e0;
}

.custom-template h4 {
  margin: -10px 0 24px;
  color: #4a5568;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 16px 0 0;
  background-color: #f8f9fa;
  display: inline-block;
}

.template-input {
  width: 100%;
  min-height: 160px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  resize: vertical;
  font-size: 14px;
  line-height: 1.5;
  font-family: inherit;
  background-color: white;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

.template-input:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
}

.template-input::placeholder {
  color: #999;
}

.template-description {
  font-size: 0.875rem;
  color: #666;
  line-height: 1.5;
  margin: -12px 0 20px;
  padding: 0 4px;
}

.info-icon {
  color: #718096;
  width: 16px;
  height: 16px;
  cursor: help;
  margin-left: 4px;
}

.info-icon:hover {
  color: #4a5568;
}

.info-text {
  font-size: 0.875rem;
  color: #666;
  background-color: #f8f9fa;
  padding: 12px 0;
  margin: 12px 0 20px 28px;
  line-height: 1.4;
  animation: fadeIn 0.2s ease-in;
  width: calc(100% - 28px);
  box-sizing: border-box;
  text-align: left;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.template-selection {
  margin-bottom: 20px;
}

.template-option {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  padding-left: 0;
}

.template-option input[type="radio"] {
  margin: 0;
}

.template-option label {
  font-size: 0.95rem;
  color: #2d3748;
  cursor: pointer;
}

.info-text {
  font-size: 0.875rem;
  color: #666;
  background-color: #f8f9fa;
  padding: 8px 12px;
  border-radius: 6px;
  margin: 8px 0 16px 24px;
  line-height: 1.4;
  animation: fadeIn 0.2s ease-in;
}

.info-icon {
  color: #718096;
  width: 14px;
  height: 14px;
  cursor: help;
}

.info-icon:hover {
  color: #4a5568;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.template-subheader {
  color: #666;
  font-size: 0.875rem;
  margin: -16px 0 16px 0; /* Negative top margin to bring it closer to title */
  font-weight: normal;
}