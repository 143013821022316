.filter-panel-right {
  position: absolute;
  min-height: 200px;
  right: 10px;
  left: 10px;
  top: 120px;
  background: var(--panel-bg, #ffffff);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 12px;
  z-index: 1001;
  animation: slideIn 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  transition: background-color 0.3s ease;
}

.filter-panel-content {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-top: 10px;
  width: 100%;
}

.settings-icon {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  transition: color 0.3s ease;
}

.settings-icon:hover {
  color: #3B82F6;
}

.filter-panel-header {
  width: 100%;
  padding-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: normal;
}

.close-button {
  position: absolute;
  right: 6px;
  top: 9px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
}

.close-button:hover {
  color: #ff4444;
}

.radio-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 12px;
}

.radio-option {
  position: relative;
  padding: 12px 15px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.radio-option input[type="radio"] {
  display: none;
}

.radio-option-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radio-option-label {
  font-weight: 600;
  font-size: 16px;
  margin-left: 28px;
}

.radio-option-description {
  font-size: 13px;
  color: #666;
  background: rgba(0, 0, 0, 0.04);
  padding: 8px 12px;
  border-radius: 8px;
  margin-top: 6px;
  line-height: 1.3;
}

/* General Option */
.radio-option.general {
  background: rgba(74, 144, 226, 0.1) !important;
  border: 2px solid transparent;
}

.radio-option.academic {
  background: rgba(124, 58, 237, 0.1) !important;
  border: 2px solid transparent;
}

.radio-option.rx {
  background: rgba(16, 185, 129, 0.1) !important;
  border: 2px solid transparent;
}

/* Custom Radio Button */
.radio-option-label::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 14px;
  width: 18px;
  height: 18px;
  border: 2px solid #ddd;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.radio-option.general input[type="radio"]:checked + .radio-option-content .radio-option-label::before {
  border-color: #4A90E2;
  background: #4A90E2;
}

.radio-option.academic input[type="radio"]:checked + .radio-option-content .radio-option-label::before {
  border-color: #7C3AED;
  background: #7C3AED;
}

.radio-option.rx input[type="radio"]:checked + .radio-option-content .radio-option-label::before {
  border-color: #10B981;
  background: #10B981;
}

/* Selected panel backgrounds */
.filter-panel-right[data-mode="general"] {
  --panel-bg: rgba(74, 144, 226, 0.02);
}

.filter-panel-right[data-mode="academic"] {
  --panel-bg: rgba(124, 58, 237, 0.02);
}

.filter-panel-right[data-mode="rx"] {
  --panel-bg: rgba(16, 185, 129, 0.02);
}

.quick-picks-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.quick-picks-header h3 {
  margin: 0;
}

.header-right {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 15px;
}

.quick-picks {
  padding: 0 1px;
  margin-top: -10px;
  position: relative;
  z-index: 2;
}

.rx-options {
  margin-top: 20px;
  width: 100%;
}

.include-patient-data {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.include-patient-data label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
}

.checkbox-label {
  color: #666;
}

.option-selection {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.option-box {
  flex: 1;
  padding: 8px 12px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}

.option-box:hover {
  background-color: #e5e5e5;
}

.option-box.selected {
  background-color: #3B82F6;
  color: white;
  border-color: #2563EB;
}

/* Input Field and Actions */
.inputs-container {
  display: none;
  width: 100%;
}

.inputs-container.active {
  display: block;
  max-height: 150px;
  overflow-y: auto;
  margin-top: 10px;
}

.input-with-actions {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.input-field {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.checkmark-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-left: 5px;
  color: green;
}

/* Tags Container */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  background-color: transparent;
  padding: 10px 0;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: #fbbf24;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 5px;
}

.delete-tag-btn {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
  padding: 2px 5px;
}

.clear-all-btn {
  background-color: #dc3545;
  border: none;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.clear-all-btn:hover {
  background-color: #c82333;
}

.clear-all-btn svg {
  margin-right: 5px;
}

.quick-picks-buttons {
  /* Add these properties to hide the scrollbar in Chrome */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.quick-picks-buttons::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.chat-type-container {
  margin-bottom: 15px;
  position: relative;
  border-radius: 12px;
  z-index: 2;
}

.chat-type-bar {
  padding: 12px 20px 12px 20px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 12px 12px 0 0;
  min-height: 18px;
  position: relative;
  gap: 20px;
}

.chat-type-info {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
 min-width: 0;
}

.chat-type-icon {
  font-size: 1.1em;
  width: 20px;
}

.chat-type-title {
  font-weight: 500;
}

.chat-type-tag {
  font-size: 0.85em;
  opacity: 0.7;
  padding-left: 10px;
  position: relative;
}

.chat-type-tag::before {
  content: '|';
  position: absolute;
  left: 0;
  opacity: 0.5;
}

/* Mode-specific bar colors */
.chat-type-bar.general {
  background: rgba(74, 144, 226, 0.1);
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
}

.chat-type-bar.academic {
  background: rgba(124, 58, 237, 0.1);
  border-bottom: 2px solid rgba(124, 58, 237, 0.3);
}

.chat-type-bar.rx {
  background: rgba(16, 185, 129, 0.1);
  border-bottom: 2px solid rgba(16, 185, 129, 0.3);
}

.chat-type-selector {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transform: translateY(-10px);
}

.chat-type-selector.expanded {
  max-height: 500px;
  opacity: 1;
  transform: translateY(0);
  padding: 15px;
  background: var(--panel-bg, #ffffff);
  border-radius: 0 0 12px 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 3;
}

.rx-settings {
  margin-top: 20px;
  width: 100%;
}

.option-selection {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.option-box {
  flex: 1;
  padding: 8px 12px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}

.option-box:hover {
  background-color: #e5e5e5;
}

.option-box.selected {
  background-color: #3B82F6;
  color: white;
  border-color: #2563EB;
}

.content-area {
  padding: 20px;
  position: relative;
  z-index: 1;
}

.quick-picks {
  margin-bottom: 15px;
}

.quick-picks-buttons {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 5px 0;
}

/* Add this new container style */
.controls-container {
  border-radius: 12px;
  padding: 0;
  min-height: calc(100vh - 42px);
  transition: all 0.3s ease;
  box-shadow: none;
  position: relative;
}

/* Remove the pseudo-element */
.controls-container::before {
  display: none;
}

/* Mode-specific styles with more subtle gradients */
.controls-container.general {
  background: radial-gradient(
    circle at center,
    rgba(74, 144, 226, 0.01) 0%,
    rgba(74, 144, 226, 0.03) 50%,
    rgba(74, 144, 226, 0.06) 100%
  );
  border: 1px solid rgba(74, 144, 226, 0.2);
}

.controls-container.academic {
  background: radial-gradient(
    circle at center,
    rgba(124, 58, 237, 0.01) 0%,
    rgba(124, 58, 237, 0.03) 50%,
    rgba(124, 58, 237, 0.06) 100%
  );
  border: 1px solid rgba(124, 58, 237, 0.2);
}

.controls-container.rx {
  background: radial-gradient(
    circle at center,
    rgba(16, 185, 129, 0.01) 0%,
    rgba(16, 185, 129, 0.03) 50%,
    rgba(16, 185, 129, 0.06) 100%
  );
  border: 1px solid rgba(16, 185, 129, 0.2);
}

/* Update these existing styles */
.chat-type-container {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.quick-picks {
  margin: 5px 0;
  padding: 0 15px;
}

.quick-pick-button {
  background: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  box-shadow: 
    0 2px 4px rgba(66, 133, 244, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.2);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.quick-pick-button:hover {
  background: #3367d6;
  transform: translateY(-1px);
  box-shadow: 
    0 4px 8px rgba(66, 133, 244, 0.3),
    inset 0 1px 1px rgba(255, 255, 255, 0.2);
}

.quick-pick-button:active {
  transform: translateY(1px);
  box-shadow: 
    0 1px 2px rgba(66, 133, 244, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1);
}

.quick-picks-buttons {
  display: flex;
  gap: 12px;
  padding: 5px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.quick-picks-buttons::-webkit-scrollbar {
  display: none;
}

/* Add Quick Pick button */
.add-quick-pick {
  background: rgba(66, 133, 244, 0.1);
  color: #4285f4;
  border: 2px dashed #4285f4;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s ease;
}

.add-quick-pick:hover {
  background: rgba(66, 133, 244, 0.15);
  transform: translateY(-1px);
}

.add-quick-pick:active {
  transform: translateY(1px);
}

/* Edit icon styling */
.edit-icon {
  opacity: 0.6;
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.quick-pick-button:hover .edit-icon {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

/* Base styles for radio options */
.radio-option {
  position: relative;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.radio-option.general {
  background: rgba(74, 144, 226, 0.1) !important;
  border: 2px solid transparent;
}

.radio-option.academic {
  background: rgba(124, 58, 237, 0.1) !important;
  border: 2px solid transparent;
}

.radio-option.rx {
  background: rgba(16, 185, 129, 0.1) !important;
  border: 2px solid transparent;
}

/* Remove ALL selection background changes */
.radio-option input[type="radio"]:checked + .radio-option-content,
.radio-option input[type="radio"]:checked + .radio-option-content:hover {
  background: none !important;
}

/* Only keep the radio circle indicator */
.radio-option-label::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 15px;
  width: 18px;
  height: 18px;
  border: 2px solid #ddd;
  border-radius: 50%;
  transition: all 0.2s ease;
}

/* Mode-specific icon colors */
.chat-type-bar.general .chat-type-icon {
  color: #4A90E2;
}

.chat-type-bar.academic .chat-type-icon {
  color: #7C3AED;
}

.chat-type-bar.rx .chat-type-icon {
  color: #10B981;
}

/* Add these styles */
.greeting-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  z-index: 1;
}

/* Add these new styles */
.chat-type-metadata {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.85em;
  opacity: 0.7;
  flex: 0 1 auto;
}

.patient-indicator {
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.no-patient {
  font-style: italic;
  color: #666;
}

.chevron-container {
  margin-left: 20px;
  flex: 0 0 auto;
}