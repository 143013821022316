/* PatientFilterContainer.css */
.patient-filter-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-top: none;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.recommended-patient-button {
  background-color: #93C5FD;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 6px #b0c4de;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.recommended-patient-button:hover {
  background-color: #93C5FD;
  box-shadow: 0 4px 8px #b0c4de;
}

.recommended-patient-button:active {
  background-color: #3B82F6;
  box-shadow: 0 2px 4px #b0c4de;
}

.schedule-based {
  display: flex;
  align-items: center;
}

.schedule-based-toggle {
  width: 20px;
  height: 20px;
  background-color: #cccccc;
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.schedule-based-toggle.active {
  background-color: #3B82F6;
}

.bottom-row {
  display: flex;
  justify-content: space-between;
}

.filter-item {
  flex: 1;
  margin-right: 10px;
}

.filter-item:last-child {
  margin-right: 0;
}

.filter-item .react-select__control {
  border-radius: 18px;
  border: 1px solid #ccc;
  background: white;
  color: black;
  font-size: 14px;
  padding: 6px;
  transition: border-color 0.3s;
}

.filter-item .react-select__control:hover {
  border-color: #93C5FD;
}

.filter-item .react-select__control--is-focused {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 1px #3B82F6;
}