.comments-section {
  margin-top: 40px;
  padding: 40px;
  border-top: 1px solid #e2e8f0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comments-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0;
}

.comment-thread-wrapper {
  display: flex;
  width: 100%;
  scroll-margin-top: 100px;
}

.comment-indent-lines {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.indent-line {
  width: 40px;
  border-right: 2px solid #e2e8f0;
  margin-right: 0;
  opacity: 0.5;
}

.comment-content-wrapper {
  flex: 1;
  min-width: 0;
  padding-left: 16px;
}

.comment {
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 1px;
  background: #f8fafc;
}

.comment-collapse-button {
  padding: 4px;
  margin-right: 8px;
  cursor: pointer;
  color: #64748b;
}

.comment-collapse-button:hover {
  color: #4a5568;
}

.comment-main {
  flex: 1;
  min-width: 0;
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.comment-author {
  font-weight: 600;
  color: #2d3748;
  margin-right: 8px;
}

.comment-timestamp {
  font-size: 0.875rem;
  color: #718096;
}

.comment-content {
  color: #4a5568;
  line-height: 1.5;
  margin-bottom: 8px;
  word-wrap: break-word;
}

.comment-actions {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

.upvote-button, 
.reply-button {
  background: none;
  border: none;
  color: #64748b;
  font-size: 0.875rem;
  padding: 4px 8px;
  cursor: pointer;
}

.upvote-button:hover,
.reply-button:hover {
  background: #f7fafc;
  color: #4a5568;
}

.upvote-button.upvoted {
  color: #3B82F6;
}

.replies-wrapper {
  margin-top: 8px;
  margin-bottom: 16px;
}

.new-comment-form {
  margin-bottom: 24px;
}

.comment-input {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 12px;
  resize: vertical;
  font-family: inherit;
}

.comment-input:focus {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 1px #3B82F6;
}

.comment-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.submit-button,
.cancel-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.submit-button {
  background: #3B82F6;
  color: white;
  border: none;
}

.submit-button:hover {
  background: #2563eb;
}

.submit-button:disabled {
  background: #93c5fd;
  cursor: not-allowed;
}

.cancel-button {
  background: white;
  color: #4a5568;
  border: 1px solid #e2e8f0;
}

.cancel-button:hover {
  background: #f7fafc;
}

.reply-form {
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 16px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

html {
  scroll-behavior: smooth;
}

.comment-thread-wrapper:not(:has(.indent-line)) {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e2e8f0;
}

.comment-thread-wrapper:not(:has(.indent-line)):last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.comment-thread-wrapper:not(:has(.indent-line)) > .comment-content-wrapper > .comment {
  background: white;
  border: 1px solid #e2e8f0;
  margin-bottom: 24px;
}

.replies-wrapper .comment {
  background: #f8fafc;
  border: none;
  margin-bottom: 1px;
}

.indent-line {
  width: 40px;
  border-right: 2px solid #e2e8f0;
  margin-right: 0;
  opacity: 0.5;
}

.comment:hover {
  background: #f1f5f9;
}

.comment-thread-wrapper:not(:has(.indent-line)) > .comment-content-wrapper > .comment:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
}

.reply-form {
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 16px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.comments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.comments-count {
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
}

.replies-wrapper .comment-thread-wrapper {
  margin-bottom: 8px;
}

.replies-wrapper .comment-thread-wrapper:last-child {
  margin-bottom: 16px;
}

@keyframes highlightComment {
  0% {
    background-color: rgba(59, 130, 246, 0.1);
  }
  100% {
    background-color: transparent;
  }
}

.comment-thread-wrapper:target {
  animation: highlightComment 2s ease-out;
}