.scribe-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 84px);
  overflow: hidden;
  padding: 20px;
  padding-top: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.progress-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 10px;
}

.progress-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.progress-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: #666;
  font-size: 14px;
}

.content-container {
  display: grid;
  grid-template-columns: minmax(auto, 50%) 1fr;
  gap: 20px;
  min-height: 0;
  padding-bottom: 20px;
  overflow: hidden;
  height: 100vh;
}

.kanban-container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  padding-right: 20px;
}

.kanban-columns-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  padding: 1px;
}

.kanban-section {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  height: 100%;
  min-height: 0;
  overflow: hidden;
  min-width: 0;
}

.kanban-section.drag-over {
  background-color: #f7fafc;
}

.dragging {
  opacity: 0.5;
}

.kanban-section .patient-list-item {
  user-select: none;
}

.kanban-title {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  padding: 16px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.kanban-count {
  background: #f1f5f9;
  padding: 2px 10px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  color: #64748b;
  margin-left: 8px;
}

.soap-container {
  background: transparent;
  border-radius: 8px;
  padding: 1px;
  height: calc(100vh - 258px);
  overflow: hidden;
}

@media (max-width: 649px) {
  .kanban-container {
    display: none;
  }
  
  .soap-container {
    width: 100%;
  }
  
  .content-container {
    grid-template-columns: 1fr;
  }
}

/* Status-specific styles */
.kanban-section.upcoming {
  border-top: 3px solid #f97316;
  background-color: white;
}

.kanban-section.inProgress {
  border-top: 3px solid #3b82f6;
  background-color: white;
}

.kanban-section.complete {
  border-top: 3px solid #10b981;
  background-color: white;
}

/* Add these styles to your existing Scribe.css file */

.patient-list-item {
  display: flex;
  position: relative;
  height: 70px;
  box-sizing: border-box;
  cursor: grab;
  transition: all 0.2s ease;
  width: 100%;
  flex-shrink: 0;
}

.patient-list-item .category-strip {
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.patient-list-item .patient-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding: 10px 12px;
  padding-right: 48px;
  background-color: white;
  margin-left: 4px;
  border-radius: 0 4px 4px 0;
  height: 100%;
  padding-left: 12px;
  padding-right: 8px;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.patient-list-item:hover .patient-info {
  background-color: #dbeafe !important;  /* Tailwind's blue-100 */
  border-color: #93c5fd !important;  /* Tailwind's blue-300 */
}

.patient-list-item.selected .patient-info {
  background-color: rgb(207 250 254) !important; /* Tailwind's cyan-100 */
  border: 2px solid rgb(6 182 212) !important; /* Tailwind's cyan-500 */
  border-left: none !important;
  box-shadow: 0 2px 4px rgba(6, 182, 212, 0.1);
  margin-top: -1px;
  margin-bottom: -1px;
  height: calc(100% + 2px);
}

.patient-list-item.selected:hover .patient-info {
  background-color: rgb(165 243 252) !important; /* Tailwind's cyan-200 */
}

.patient-list-item.selected .patient-name {
  font-weight: 600;
  color: #1e40af; /* Tailwind's blue-800 */
}

.patient-list-item.selected .category-strip {
  width: 6px; /* Slightly wider than normal */
}

.patient-list-item .patient-name {
  font-size: 16px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  margin-bottom: 0;
}

.patient-list-item .patient-time {
  font-size: 14px;
  line-height: 1.2;
  color: #666;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: calc(100% - 28px);
}

/* Remove any box-shadow from the existing styles */
.patient-list-item {
  box-shadow: none;
}

/* Empty state styles */
.kanban-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: #a0aec0;
  text-align: center;
  gap: 8px;
}

.kanban-empty svg {
  font-size: 24px;
  margin-bottom: 8px;
}

/* Drag and drop styles (if you want to add that functionality later) */
.patient-list-item.dragging {
  opacity: 1 !important;
  background: white !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.patient-list-item.dragging .patient-info {
  background-color: #CCF1F6 !important;
  border-color: #67e8f9 !important;
  opacity: 1 !important;
}

.kanban-section.drag-over {
  background-color: #f1f5f9;
}

/* Adjust the medical-scribe-container to fit within its parent */
.medical-scribe-container {
  height: 100% !important;
  min-height: 0;
}

/* Ensure the medical-scribe-box scales properly */
.medical-scribe-box {
  min-height: 0;
  overflow: auto;
}

/* Improve the drag handle appearance */
[role="button"] {
  cursor: grab;
}

[role="button"]:active {
  cursor: grabbing;
}

/* Smooth transitions for drag and drop */
.patient-list-item {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.patient-list-item.dragging {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Sticky header container */
.kanban-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  padding: 16px 16px 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 2px solid #f7fafc;
}

/* Scrollable content area */
.kanban-content {
  padding: 16px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 transparent;
  flex-grow: 1;
}

.kanban-content::-webkit-scrollbar {
  width: 6px;
}

.kanban-content::-webkit-scrollbar-track {
  background: transparent;
}

.kanban-content::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 3px;
}

/* Add a separate scrollable container for the cards */
.kanban-cards-container {
  flex: 1;
  min-height: 0;
  background-color: transparent;
  overflow-y: auto;
  padding: 12px 8px;
  min-width: 0;
}

.kanban-cards-container::-webkit-scrollbar {
  width: 8px;
}

.kanban-cards-container::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
}

.kanban-cards-container::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 4px;
  border: 2px solid #f1f5f9;
}

/* Update dragging styles */
.patient-list-item.dragging {
  opacity: 1 !important;
  background: white !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.patient-list-item.dragging .patient-info {
  background-color: #CCF1F6 !important;
  border-color: #67e8f9 !important;
  opacity: 1 !important;
}

/* Remove pointer cursor and update drag cursor */
.patient-list-item {
  cursor: grab;
}

.patient-list-item:active {
  cursor: grabbing;
}

/* Add drag-over state for columns */
.kanban-section.drag-over {
  border: none;
  background-color: transparent;
}

/* Remove click transition */
.patient-list-item .patient-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  margin-left: 4px;
  border-radius: 0px 4px 4px 0;
  height: 100%;
  padding-left: 20px;
  /* Remove transition to prevent click animation */
}

/* Update hover state */
.patient-list-item:hover .patient-info {
  background-color: #f8fafc;  /* Tailwind's blue-100 */
  border-color: #93c5fd;  /* Tailwind's blue-300 */
}

/* Remove dashed border from dragged item and adjust opacity */
.patient-list-item.dragging {
  opacity: 0.6;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Ensure the item is grabbable */
.patient-list-item {
  cursor: grab;
  user-select: none; /* Prevent text selection while dragging */
  -webkit-user-select: none;
}

/* Update drag-over state for columns only */
.kanban-section.drag-over {
  border: 2px dashed #cbd5e0;
  background-color: #f7fafc;
}

/* Add these styles */
.patient-list-item {
  position: relative;
  /* ... existing styles ... */
}

.patient-list-item:hover .patient-info {
  background-color: #f8fafc; /* Light hover background */
}

/* Ensure the button transitions smoothly */
.patient-list-item button {
  transition: all 0.2s ease;
}

/* Remove any conflicting hover states */
.patient-list-item:hover .patient-info {
  background-color: #f8fafc;  /* This ensures we can see the button */
}

.patient-list-item.selected {
  background-color: rgb(207 250 254); /* Tailwind's cyan-100 */
  border-color: rgb(6 182 212); /* Tailwind's cyan-500 */
}

.patient-list-item.selected:hover {
  background-color: rgb(165 243 252); /* Tailwind's cyan-200 */
}

/* Add these styles */
.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker__input-container button {
  font-size: 0.875rem;
  font-weight: 500;
}

.react-datepicker-popper {
  z-index: 50;
}

/* Date header container */
.kanban-container > div:first-child {
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Center date display */
.kanban-container > div:first-child .react-datepicker-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  color: #1e293b;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.kanban-container > div:first-child .react-datepicker-wrapper:hover {
  background-color: #f1f5f9;
}

/* Calendar icon */
.kanban-container > div:first-child .react-datepicker-wrapper svg {
  color: #64748b;
  margin-right: 8px;
}

/* Navigation buttons */
.kanban-container > div:first-child button[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}

/* Arrow buttons */
.kanban-container > div:first-child button:first-child,
.kanban-container > div:first-child button:last-child {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #64748b;
  background-color: transparent;
  transition: all 0.2s ease;
}

.kanban-container > div:first-child button:first-child:hover:not([disabled]),
.kanban-container > div:first-child button:last-child:hover:not([disabled]) {
  background-color: #f1f5f9;
  color: #334155;
}

/* Date picker button */
.kanban-container > div:first-child .react-datepicker-wrapper button {
  padding: 0 16px;
  height: 36px;
  font-weight: 500;
  color: #1e293b;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
  background-color: #f8fafc; /* Light background to indicate clickability */
  border: 1px solid #e2e8f0;
}

/* Calendar icon */
.kanban-container > div:first-child .react-datepicker-wrapper button svg {
  color: #64748b;
  font-size: 16px;
}

/* Hover state */
.kanban-container > div:first-child .react-datepicker-wrapper button:hover {
  background-color: #f1f5f9;
  border-color: #cbd5e0;
}

/* Status-specific title colors */
.kanban-section.upcoming .kanban-title {
  color: #c2410c; /* Orange-700 */
}

.kanban-section.inProgress .kanban-title {
  color: #1d4ed8; /* Blue-700 */
}

.kanban-section.complete .kanban-title {
  color: #047857; /* Emerald-700 */
}

/* Date picker container */
.react-datepicker-popper {
  z-index: 1000 !important; /* Ensure calendar appears above all other elements */
}

/* Date picker button styles */
.kanban-container > div:first-child button {
  padding: 8px 12px; /* Consistent padding */
  border-radius: 6px;
  transition: background-color 0.2s ease;
  height: 36px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent; /* Prevent layout shift */
}

/* Hover state for date picker button */
.kanban-container > div:first-child button:hover {
  background-color: #f1f5f9;
  border-color: transparent; /* Keep border transparent on hover */
}

/* Active state for date picker button */
.kanban-container > div:first-child button:active {
  background-color: #e2e8f0;
  transform: none; /* Prevent any transform on click */
}

/* Disabled state for navigation buttons */
.kanban-container > div:first-child button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Calendar icon alignment */
.kanban-container > div:first-child button .fa-calendar {
  margin-right: 8px;
}

/* Update drag-over state to only affect cards container */
.kanban-cards-container.drag-over {
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px dashed #cbd5e0;
}

/* Remove drag-over from kanban section */
.kanban-section.drag-over {
  border: none;
  background-color: transparent;
}

.draggable-item {
  cursor: grab;
}

.draggable-item:active {
  cursor: grabbing;
}

/* When dragging is in progress */
.dragging {
  cursor: grabbing !important;
}

/* Add these styles for smooth list transitions */
.patient-list-item {
  /* Update existing transition to include transform */
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
  will-change: transform, opacity;
}

/* Add animation for entering a new list */
.patient-list-item.entering {
  opacity: 0;
  transform: translateY(20px);
}

/* Add animation for leaving a list */
.patient-list-item.leaving {
  opacity: 0;
  transform: translateY(-20px);
}

/* Smooth the kanban cards container transitions */
.kanban-cards-container {
  transition: padding 0.3s ease;
}

/* Add a subtle scale effect when moving */
.patient-list-item.moving {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Ensure parent containers don't constrain width */
.kanban-cards-container {
  width: 100%;
  min-width: 0;
}

.patient-list-item {
  width: 100%;
  min-width: 0;
}

.appointment-group {
  margin-bottom: 1rem;
}

.appointment-group-header {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #6b7280;
  background-color: #f3f4f6;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
}

/* Add this to hide scrollbars while maintaining scroll functionality */
* {
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
  display: none; /* WebKit */
}