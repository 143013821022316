.primer-patient-item {
  display: flex;
  margin-top: 10px;
  position: relative;
  height: 70px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease;
}

.primer-patient-item .category-strip {
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.primer-patient-item .patient-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  background-color: #f8fafc;
  margin-left: 4px;
  border-radius: 0 4px 4px 0;
  height: 100%;
  padding: 10px 12px;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  width: calc(100% - 4px);
  overflow: hidden;
}

.primer-patient-item:hover .patient-info {
  background-color: #dbeafe;
  border-color: #93c5fd;
}

.primer-patient-item.selected .patient-info {
  background-color: rgb(207 250 254);
  border: 2px solid rgb(6 182 212);
  border-left: none;
  box-shadow: 0 2px 4px rgba(6, 182, 212, 0.1);
  margin-top: -1px;
  margin-bottom: -1px;
  height: calc(100% + 2px);
}

.primer-patient-item.selected:hover .patient-info {
  background-color: rgb(165 243 252);
}

.primer-patient-item.selected .patient-name {
  font-weight: 600;
  color: #1e40af;
}

.primer-patient-item .patient-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: calc(100% - 28px);
  color: #1e293b;
  margin-bottom: 0;
}

.primer-patient-item .patient-time {
  font-size: 14px;
  line-height: 1.2;
  color: #64748b;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: calc(100% - 28px);
}

.primer-patient-item .status-indicators {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.primer-patient-item .alert-icon {
  color: #ef4444;
  width: 18px;
  height: 18px;
}

.primer-patient-item .check-icon {
  color: #4caf50;
  width: 18px;
  height: 18px;
}

.primer-patient-item .tasks-icon {
  color: #6366f1;
  width: 18px;
  height: 18px;
}

/* Add this to ensure proper scrolling behavior */
.primer-patients-list {
  scroll-behavior: smooth;
  overflow-y: auto;
}

/* Optional: Add padding to prevent selected items from being too close to the edges */
.primer-patient-item.selected {
  scroll-margin: 8px;
} 